import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import Seo from "src/components/Seo";
import PPM from "src/routes/PPM";
import { PageProps } from "src/types";

const PotentialProjectMarket: React.SFC<PageProps> = (props) => {
  const data = useStaticQuery(query);
  return (
    <>
      <Seo pageTitle="Potential Project Market" />
      <PPM {...props} data={data.ppm19.data} />
    </>
  );
};

const query = graphql`
  query {
    ppm19 {
      data {
        _id
        createdAt
        description
        duration
        goodId
        is19PPM
        good {
          createdAt
          name
          price
          type
          updatedAt
        }
        isPublished
        name
        order
        teachers {
          updatedAt
          name
          img
          description
          createdAt
        }
        trailer
        updatedAt
        teachersIds
        videosIds
        videos {
          name
          duration
          previews {
            m {
              height
              link
              width
            }
            s {
              height
              link
              width
            }
            l {
              height
              link
              width
            }
            xl {
              height
              link
              width
            }
          }
        }
      }
    }
  }
`;

export default PotentialProjectMarket;
